import { ref } from 'vue';

import { store } from '@/store/index';
import { readCompanyId } from '@/store/main/getters';
import {
  dispatchUpdateCompanyTag,
  dispatchCreateCompanyTag,
  dispatchDeleteCompanyTag,
  dispatchGetCompanyTags,
} from '@/store/main/actionsMain';
import { Tag } from '@/interfaces/model/general/tag';

export interface CompanyTagFormData {
  id?: number;
  name: string;
  color: number;
}

export const useTagComposable = () => {
  const isLoading = ref<boolean>(false);
  const tags = ref<Array<Tag>>([]);

  const getCompanyTag = async (): Promise<void> => {
    isLoading.value = true;
    tags.value.splice(0);

    const response = await dispatchGetCompanyTags(store, {
      companyId: readCompanyId(store),
    });
    if (response) {
      tags.value.push(
        ...response.data.map((x: Tag) => {
          return {
            name: x.name,
            id: x.id,
            color: x.color,
          };
        })
      );
    }
    isLoading.value = false;
  };

  const createCompanyTag = async (form: CompanyTagFormData) => {
    await dispatchCreateCompanyTag(store, {
      payload: {
        name: form.name,
        color: form.color,
      },
      companyId: readCompanyId(store),
    });
  };

  const deleteCompanyTag = async (id: number): Promise<void> => {
    await dispatchDeleteCompanyTag(store, {
      id: id,
      companyId: readCompanyId(store),
    });
  };

  const updateCompanyTag = async (
    id: number,
    name: string,
    color: number
  ): Promise<void> => {
    await dispatchUpdateCompanyTag(store, {
      id: id,
      companyId: readCompanyId(store),
      body: {
        name: name,
        color: color,
      },
    });
  };

  const getJoinedTagName = (index: number) => {
    const tag = tags.value.find((tag: Tag) => tag.id === index);
    return tag ? tag.name : '';
  };

  const getJoinedTagColor = (index: number) => {
    const tag = tags.value.find((tag: Tag) => tag.id === index);
    return tag ? tag.color : '';
  };

  return {
    isLoading,
    tags,
    createCompanyTag,
    getCompanyTag,
    deleteCompanyTag,
    updateCompanyTag,
    getJoinedTagName,
    getJoinedTagColor,
  };
};
